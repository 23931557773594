import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import queryString from "querystring";

import Layout from "../components/Layout";

export const IndexPageTemplate = ({ image, title }) => {
  let src;
  if (image.childImageSharp) {
    src = image.childImageSharp.fluid.src;
  } else if (image.publicURL) {
    src = image.publicURL;
    src = `${src}?${queryString.stringify({
      nf_resize: "fit",
      w: 1200
    })}`;
  }
  return (
    <div>
      <div
        className="margin-top-0"
        style={{
          backgroundImage: `url(${src})`,
          backgroundPosition: `center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: "80vh"
        }}
      />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate image={frontmatter.image} title={frontmatter.title} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title

        image {
          publicURL
        }
        # {
        #   childImageSharp {
        #     fluid(maxWidth: 2048, quality: 100) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
      }
    }
  }
`;
